import { combineReducers } from "redux";

import AppReducer from "../core/app.store";
import OpenLayerReducer from "./store/openlayer/openlayer.store";
import InitMapReducer from "./store/init-map/init-map.store";
import LoadingReducer from "./store/loading/loading.store";
import DocumentRepositoryReducer from "./store/document-repository/document-repository.store";
import DocumentManagementReducer from "./store/document/document-management-redux.store";

export default combineReducers({
  app: AppReducer,
  openLayer: OpenLayerReducer,
  initMap: InitMapReducer,
  loadingState: LoadingReducer,
  documentRepository: DocumentRepositoryReducer,
  documentManagement: DocumentManagementReducer,
});
